// custom typefaces
// import 'typeface-montserrat'
// import 'typeface-merriweather'
// import 'typeface-caveat'

import 'typeface-fira-sans'

import 'prismjs/themes/prism-tomorrow.css'

import 'prismjs/plugins/line-numbers/prism-line-numbers.css'

import './src/styles/global.css'
